<template>
  <div class="item">
    <TitleHeader msg="我的赠品"></TitleHeader>
    <el-row class="elBox">
        <el-col :gutter="20">
            <el-col class="GiftBox" :span="8" v-for='(item,index) in GiftList' :key="index" :couponItem="item">
                    <el-col :span="8" class="GiftLeft">
                      <img :src="item.productImg" :alt="item.productName">
                    </el-col>
                    <el-col :span="16" class="GiftRight">
                       <p :title="item.productName"><span>赠品</span>{{item.productName}}</p>
                       <p>数量：{{item.actualNum}}{{item.unit}}</p>
                       <p>满 {{item.nextAmount}}元 赠送</p>
                       <p> {{item.validBeginDate.substring(0,10)}} 至 {{item.validEndDate.substring(0,10)}}</p>
                    </el-col>
                   
                </el-col>
        </el-col>
    </el-row>
  </div>
</template>

<script>
import {memberGiftList} from 'api/member.js';
const TitleHeader = ()=>import("components/common/TitleHeader.vue");
  export default {
    name:"Coupon",
    data() {
      return {
         total: 0,
        current: 1, //当前页
        size: 12, //每页数量
        GiftList:''
    }
    },
    methods:{
        //  获取查看我的赠品列表
        memberGiftList(){
            memberGiftList().then(data=>{
                console.log('memberGiftList',data.data.data)
            this.GiftList=data.data.data
            this.total = Number(data.data.data.length);
            })
        }
    },
   created(){
     this.memberGiftList()
    },
     components:{
       TitleHeader,
    }
  }
</script>

<style lang="less" scoped>
@import '~style/index.less';
.pageBox {
  float: left;
  width: 100%;
  .el-pagination {
    padding-top: 40px;
    text-align: center;
    float: right;
  }
}
.item {
  position: relative;
 
  .elBox{
    margin-top:20px
  }
    .GiftBox {
      padding:4px;
      border:1px solid #f6f6f6;
      background: #f6f6f6;
      .GiftLeft{
          padding-right:10px;
          img{
              width: 100%;
              height: auto;
          }
      }
      .GiftRight{
        p:nth-child(1){
            font-size: 16px;
            font-weight: bold;
            line-height:30px;
            width:200px;
            white-space:nowrap;
            text-overflow:ellipsis;
            overflow:hidden;
            span{
                height:24px;
                 line-height:24px;
                float: left;
                border-radius: 5px;
                padding: 0 3px;
                font-size:14px;
                background: #ff4d00;
                color: #fff;
                margin-right:3px;  
                margin-top: 3px;
            }
        } 
         p:nth-child(2){
              font-size:14px;
              color: #666;
              line-height: 24px;
         }
          p:nth-child(3){
              font-size:14px;
              color: #666;
              line-height: 24px;
         }
          p:nth-child(4){
              font-size:14px;
              color: #666;
              line-height: 24px;
         }
          
      }
    }
   
}
 
</style>
